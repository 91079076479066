
import { useCallback, useState } from 'react';
import './index.css';

function App() {
  const [step, setStep] = useState(0);

  const submitForm = useCallback(async (event) => {
    event.preventDefault();

    const { currentTarget } = event;
    const formData = new FormData(currentTarget);

    const data = {
      email: formData.get('email'),
      userGroup: 'Indexed Waitlist',
    };

    const formBody = `userGroup=${encodeURIComponent(data.userGroup)}&email=${encodeURIComponent(data.email)}`;

   await fetch('https://app.loops.so/api/newsletter-form/cliag3tmu000gmf0fjfhvn8rj', {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then(() => {
      setStep(1);
      currentTarget.reset();
    })
    .catch(() => {
      return;
    });

  }, []);

    return (
      <>
      <div className="bg-white dark:bg-indigo-950 h-screen w-full flex items-center align-middle justify-center">
        <div className="max-w-7xl sm:px-6 lg:px-8 px-4">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl rounded-3xl sm:px-24 xl:py-32">
            <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Indexed - Early Access Waitlist
            </h2>
            <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">

            Indexed, our upcoming app, accelerates website page indexing on search engines and offers tools for improved site rankings.
            </p>
            {step === 0 && (
            <form className="mx-auto mt-10 flex max-w-md gap-x-4" onSubmit={submitForm}>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <input type="hidden" name="userGroup" value="Indexed Waitlist"></input>
              <button
                type="submit"
                className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Notify Me
              </button>
            </form>
            )}
            {step !== 0 && (
              <div className='flex flex-col gap-2 items-center align-middle justify-center mt-16 text-xl text-white'>
                <p>Thank you for signing up! We'll be in touch soon.</p>
                <p>You can also join our <a href="https://discord.gg/RZ35h3C9Pd" target="_blank" rel="noreferrer" className='underline'>Discord</a> and get early previews!</p>
              </div>
            )}
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
              aria-hidden="true"
            >
              <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
              <defs>
                <radialGradient
                  id="759c1415-0410-454c-8f7c-9a820de03641"
                  cx={0}
                  cy={0}
                  r={1}
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(512 512) rotate(90) scale(512)"
                >
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      </>
  );
}

export default App;
